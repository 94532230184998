*{
  padding: 0px;
  margin: 0px;
  box-sizing: border-box;
}
body{
  background-color: #F7F9FE !important;
}
.app{
  position: relative;
  background: transparent;
  max-width: 100vw;
  overflow-x: hidden;
}
.row, .col{
  background: transparent !important;
}
.app::before{
  position: absolute;
  content: "";
  background: url('https://res.cloudinary.com/dkssurk1s/image/upload/v1648716889/idPass-web-assets/Dots_qin67c.svg')center center no-repeat;
  background-size: contain;
  top:0;
  left:0;
  bottom:0;
  right:0;
  z-index:-1;
}
@font-face{
  font-family: GT-black;
  src: url('assets/fonts/GTWalsheimPro-Black.ttf');
}
@font-face{
  font-family: GT-bold;
  src: url('assets/fonts/GTWalsheimPro-Bold.ttf');
}
@font-face{
  font-family: GT-medium;
  src: url('assets/fonts/GTWalsheimPro-Medium.ttf');
}
@font-face{
  font-family: GT-regular;
  src: url('assets/fonts/GTWalsheimPro-Regular.ttf');
}
@font-face{
  font-family: GT-light;
  src: url('assets/fonts/GTWalsheimPro-Light.ttf');
}
@font-face{
  font-family: GT-thin;
  src: url('assets/fonts/GTWalsheimPro-Thin.ttf');
}

h1, h2{
  font-family:GT-black;
}
h3, h4{
  font-family:GT-bold;
}
h5{
  font-family:GT-Medium;
}
h6{
  font-family:GT-regular;
}
p, a, small{
  font-family: GT-regular;
}

.btn-purple{
  background-color: #1633A3 !important;
  border: 2px solid #1633A3 !important;
  color:#fff !important;
  font-family:GT-bold;
}
.btn-purple:hover{
  color:#fff;
}
.btn-white{
  background-color: #fff !important;
  color:#000 !important;
  font-family:GT-bold;
}
/* .btn-white:hover{
  color:#303af2;
} */
.btn-white-outline{
  border: 2px solid #fff !important;
  background-color: transparent !important;
  color:#fff !important;
  font-family:GT-bold;
}
.btn-purple-outline{
  background-color: transparent;
  color:#303af2;
  border: 1px solid #303af2;
  font-family:GT-bold;
}
.btn-purple-outline:hover{
  color:#303af2;
}
.btn-black{
  background-color: #000 !important;
  color:#fff !important;
  font-family:GT-bold;
}

.bg-blue{
  color:#fff;
  text-align: center;
}
.bg-blue h1{
  font-size: 50px;
}


.link{
  color:#000 !important;
  text-decoration: none;
}

.tick{
  border: 1px solid #303AF2;
  border-radius: 50%;
  width: 20px;
  height: 20px;
}
.tick i{
  position: relative;
  background-color: #fff;
  border-radius: 50%;
  top:-5px;
  right: -5px !important;
  color: #303AF2;
}


.navbar-area .navbar-brand img{
  width:150px;
}
.navbar-area .navbar{
  background-color: #fff;
  box-shadow: 0px 0px 12px #091E4226;
}
.navbar-area .nav-item{
  position: relative;
  display: flex;
  align-items: center;
  margin-right: 15px;
}
.navbar-area .nav-item div{
  display: flex;
  align-items: center;
}
.navbar-area .nav-link{
  font: 16px GT-medium;
  color:#000 !important;
  margin-bottom: 2px;
  cursor: pointer;
}
.navbar-area .nav-item i{
  color:#000;
}

.navbar-options{
  position: absolute;
  box-shadow: 0px 4px 12px 0px #091E4226;
  background:#faf8f8;
  border-radius: 20px;
  padding: 50px 30px;
  right:5%;
  top: 50px;
  border: 1px solid #ddd;
  z-index: 4;
}
/* .navbar-area .nav-item:hover .navbar-options{
  display:block !important;
} */
.navbar-options img{
  width:40px;
  margin-right: 15px;
}
.navbar-options h6{
  font: 17px GT-bold;
  font-weight: 400 !important;
}
.navbar-options p{
  font: 12px GT-regular;
}
.navbar-options.products-options{
  left: 0;
  min-width: 650px;
}
.navbar-options.products-options::before{
  position: absolute;
  content:"" ;
  width: 0;
  height:0;
  border-top: none;
  border-right: 20px solid transparent;
  border-left: 20px solid transparent;
  border-bottom: 35px solid #faf8f8;
  top:-20px;
  left:30px;
}
.navbar-options.resources-options{
  left: 0;
  min-width: 550px;
}
.navbar-options.resources-options::before{
  position: absolute;
  content:"" ;
  width: 0;
  height:0;
  border-top:none;
  border-right: 20px solid transparent;
  border-left: 20px solid transparent;
  border-bottom: 35px solid #faf8f8;
  top:-20px;
  left:35px;
}
.navbar-options.company-options{
  right:-50px;
  min-width: 500px;
}
.navbar-options.company-options::before{
  position: absolute;
  content:"" ;
  width: 0;
  height:0;
  border-top:none;
  border-right: 20px solid transparent;
  border-left: 20px solid transparent;
  border-bottom: 35px solid #faf8f8;
  top:-20px;
  right:70px;
}

.hero-area{
  /* background: url('assets/hero.png') center center; */
  /* background: url('assets/triangles-bg.png') center center no-repeat; */
  background: url('https://res.cloudinary.com/dkssurk1s/image/upload/v1648716901/idPass-web-assets/triangles-bg_gfvpxn.png') center center no-repeat;
  background-size: cover;
  color:#fff;
  padding-top: 12.4rem;
  padding-bottom: 10.4rem;
}
.hero-area h1{
  font-size: 3.0rem;
}
.hero-area p{
  font-size: 20px;
}

.partners-area{
  position: relative;
  top:-50px
}
.partners-area .card{
  box-shadow: 0px 8px 12px 0px #091E4226;
}
.partners-area .card img{
  width:75%
}

.features-area .card{
  color: #fff;
  border-radius: 10px;
}
.features-area .first-card{
  background: #1633A3;
}
.features-area .first-card .card-body{
  position: relative;
  z-index: 1;
  overflow: hidden;
}
.features-area .first-card .card-body::before{
  position: absolute;
  content:'';
  background: url('https://res.cloudinary.com/dkssurk1s/image/upload/v1648716893/idPass-web-assets/overlay_uikglh.png');
  top: 0;
  bottom:0;
  left:0;
  right:0;
  opacity: 0.4;
  z-index: -1;
  border-radius: 10px;
}
.features-area .first-card .card-body::after{
  position: absolute;
  content:'';
  background: url('https://res.cloudinary.com/dkssurk1s/image/upload/v1648716891/idPass-web-assets/feature-africa_k4ju5m.png') no-repeat;
  background-size: contain;
  right:-70%;
  bottom:0;
  width: 100%;
  height: 100%;
  opacity: 0.9;
  z-index: -1;
}
.features-area .second-card {
  background: linear-gradient(53.82deg, #1633A3 2.15%, #A514C9 95.03%);
  min-height: 500px;
}
.features-area .second-card .card-body{
  position: relative;
  z-index: 1;
  padding: 50px 20px;
}
.features-area .second-card .card-body::before{
  position: absolute;
  content:'';
  background: url('https://res.cloudinary.com/dkssurk1s/image/upload/v1648716895/idPass-web-assets/overlay-2_xom7qq.png');
  top: 0;
  bottom:0;
  left:0;
  right:0;
  opacity: 0.3;
  z-index: -1;
  border-radius: 10px;
}
.features-area .second-card .card-body::after{
  position: absolute;
  content:'';
  background: url('https://res.cloudinary.com/dkssurk1s/image/upload/v1648716891/idPass-web-assets/feature-africa_k4ju5m.png') no-repeat;
  background-size: contain;
  right:0;
  bottom:-15%;
  width: 100%;
  height: 100%;
  opacity: 0.5;
  z-index: -1;
}
.features-area .second-card .card-body .btn{
  width: 100%;
  padding: 15px 0px;
}
.features-area .second-card .card-body p{
  padding-top:20px;
}

.across-africa-area h5{
  font:12px GT-black !important;
}
.across-africa-area .col-3{
  padding-right: 0;
}

.video-area video{
  border-radius: 15px;
  border: 1px solid #1633A3;
}

/* .consistent-area{
  position: relative;
  background: #F4F5F7;
  padding-bottom: 10rem;
}
.consistent-area::before{
  position: absolute;
  content:'';
  background: url('assets/consistent-2.png') no-repeat;
  width:50%;
  height: 80%;
  top: 35%;
  left: 20%;
}
.consistent-area .container-fluid{
  overflow: hidden;
}
.consistent-area .con-img:hover{
  z-index: 1 ;
  transform: scale(1.05);
  transition: 1000ms ease-in-out;
} */

.how-to-use-data-area .tabs{
  display: flex;
  border-bottom: 1px solid #C1C7D0;
}
.how-to-use-data-area .tabs p{
  margin:0px 50px 0px 0px;
  padding-bottom:10px ;
  cursor:pointer;
  display: flex;
  align-items: center;
}
.how-to-use-data-area .tabs p.active{
  border-bottom: 1px solid #1633A3;
  color:#1633A3;
}
.how-to-use-data-area .tabs p i{
  font-size: 20px;
  margin-right: 5px;
}
.how-to-use-data-area .how-to-steps h5, .how-to-use-data-area .how-to-steps p{
  position: relative;
  margin-left: 30px;
}
.how-to-use-data-area .how-to-steps h5:before{
  position: absolute;
  content:"";
  width: 15px;
  height: 15px;
  border-radius: 50%;
  background-color: #1633A3;
  left:-25px;
  top:5px
}
.how-to-use-data-area .how-to-steps p:before{
  position: absolute;
  content:"";
  height: 100%;
  border-left:1px solid #1633A3;
  left:-18px;
  top:-12px
}

.more-identity-graph-area .card{
  box-shadow: 0px 3.827150583267212px 19.135753631591797px 0px #091E424F;
  padding: 15px;
  animation:0.5s animation-top ease-in-out;;
}
.more-identity-graph-area .card-body{
  display: flex;
}
.more-identity-graph-area .card-body h5, .more-identity-graph-area .card-body a{
  color: #1633A3;
}
.more-identity-graph-area .inactive{
  cursor:pointer;
}
.more-identity-graph-area .inactive i{
  font-size:30px !important;
}
.more-identity-graph-area .inactive h5{
  padding: 0 !important;
  margin:0 !important
}


.api-area{
  position: relative;
  color:#fff;
  margin-top:70px;
}
.api-area::before{
  position: absolute;
  content: "";
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  background: linear-gradient(53.82deg, #1633A3 2.15%, #A514C9 95.03%);
  transform: skewY(-6deg);
  z-index: -1;
}
.api-area h1{
  font-size: 48px;
}
.api-area p{
  font-size: 20px;
}
.api-area img{
  width: 90%;
}

.every-industry-area .card{
  box-shadow: 0px 4px 12px 0px #091E4226;
  border-radius: 10px;
  border: none;
  min-height: 500px;
}
.every-industry-area i{
  width:80px;
  height: 80px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #DEEBFF;
  color:#1633A3;
  border-radius: 5px;
  font-size: 30px;
  margin: 30px 0;
}
.every-industry-area p{
  line-height: 150%;
  /* color:#000; */
  font-size: 15px;
  margin-top: 30px;
}

.customers-perspective-area{
  background: #F4F5F7;
  padding: 50px 0px 100px 0px ;
}
.customers-perspective-area .card{
  min-height: 500px;
  box-shadow: 0px 10px 18px 0px #091E4226;
}
.customers-perspective-area .card .card-header{
  background: #091E42 !important;
  padding: 20px 10px;
}
.customers-perspective-area .card-header i{
  font-size: 70px;
  color: #fff;
}
.customers-perspective-area .card-footer{
  display: flex;
  background: #fff !important;
  border: none !important;
  padding: 100px 0px 20px 0px;
  margin-top: 50px;
  align-items: center !important;
}
.customers-perspective-area .card-footer img{
  width:60px;
  margin-right: 10px;
}
.customers-perspective-area .card-footer p{
  padding: 0;
  margin: 0;
}

.react-3d-carousel .slider-container .slider-content .slider-single .slider-single-content {
  box-shadow: none !important;
  opacity: 1 !important;
} 
.react-3d-carousel .slider-container .slider-content {
  width:90% !important;
}
.react-3d-carousel .slider-container .slider-content .slider-single .slider-left div,
.react-3d-carousel .slider-container .slider-content .slider-single .slider-right div{
  background-color: #fff;
  color: #000;
  width:50px;
  height:50px;
  display: flex;
  align-items: center;
  justify-content:center;
  padding: 0;
  margin: 0;
  border:0px;
  border-radius: 50%;
  font-size:20px !important
}

.newsletter-area {
  background: #303af2;
  color:#fff;
}

.blog-card-area img{
  width: 100%;
  height: 300px;
  size:contain ;
}
.blog-card-area h4{
  overflow: hidden;
  text-overflow: ellipsis !important;
  display: -webkit-box;
  -webkit-line-clamp: 3; /* number of lines to show */
  -webkit-box-orient: vertical;
}
.blog-card-area .article{
  overflow: hidden;
  text-overflow: ellipsis !important;
  display: -webkit-box;
  -webkit-line-clamp: 3; /* number of lines to show */
  -webkit-box-orient: vertical;
}
.blog-card-area  .dateTime{
  display: flex;
  align-items: center;
  margin: 30px 0px !important;
}
.blog-card-area  a{
  text-decoration: none !important;
}

.footer-area{
  background:url('https://res.cloudinary.com/dkssurk1s/image/upload/v1648716891/idPass-web-assets/footer-bg_wdttz3.png') center center no-repeat;
  background-size: cover ;
  position: relative;
  color: #fff;
}
.footer-area .container{
  background:url('https://res.cloudinary.com/dkssurk1s/image/upload/v1648716894/idPass-web-assets/footer-world_hbeaxf.png') center center no-repeat;
  background-size: cover ;
}
.footer-area i{
  font-size: 20px;
  margin-right: 10px;
}
.footer-area a, .footer-area .link{
  color:#fff !important;
}


/* ---------- Biometric Authenticatication Page -------------- */

.bio-auth-hero{
  position: relative;
  color:#fff;
  padding-top: 2.4rem;
  padding-bottom: 10.4rem;
}
.bio-auth-hero::before{
  position: absolute;
  content: "";
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  background: linear-gradient(118.24deg, #A514C9 12.69%, #1633A3 51.06%);
  transform: skewY(-6deg);
  transform-origin: top left;
  z-index: -1;
}
.bio-auth-hero p{
  font-size: 20px;
}
.bio-auth-hero h1{
  font-size: 48px;
}
.bio-auth-hero img{
  width: 100%;
}

.how-face-works .card{
  background: #FAFBFC;
  border-radius: 10px;
}
.how-face-works span{
  font-size: 25px !important;
}
.how-face-works h5{
  font-size: 16px !important;
}
.how-face-works p{
  font-size: 15px !important;
}
.how-face-works .process{
  position: relative;
}
.how-face-works .process::before{
  position: absolute;
  content: "";
  width:2px;
  background-color:#EBECF0;
  height: 85%;
  top: 5%;
  left:-2%;
}
.how-face-works i{
  background-color: #1633A3;
  background-image: linear-gradient(53.82deg, #1633A3 2.15%, #A514C9 95.03%) !important;
  background-size: 100%;
  background-repeat: repeat;
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent; 
  -moz-background-clip: text;
  -moz-text-fill-color: transparent;
  font-size: 30px;
}
.how-face-works img{
  width:90%;
  margin:0 auto
}
.how-face-works .main-work .col-md-3{
  padding-right: 0px;
}
.how-face-works .main-work .card{
  min-height: 50px !important;
  padding: 5px 10px;
  border-radius: 5;
}
.how-face-works .main-work .card p{
  font-size: 10px !important;
}

.bio-auth-use-cases{
  background: #F4F5F7;
}
.bio-auth-use-cases .card{
  box-shadow: 0px 10px 18px 0px #091E4226;
  background: #fff;
  margin: 40px 20px 40px 0px;
  overflow: hidden;
  min-height: 425px;
}
.bio-auth-use-cases .card-body{
  position: relative;
  padding-top: 10.4rem;
}
.bio-auth-use-cases .card-body::before{
  position: absolute;
  content: "";
  width:100%;
  height:65%;
  background: url('https://res.cloudinary.com/dkssurk1s/image/upload/v1648716889/idPass-web-assets/card-bg_knpmaw.png') center center no-repeat;
  background-size: cover;
  top:-20px;
  left:-30px;
}
.bio-auth-use-cases button{
  position: absolute;
  background-color: #FFF !important;
}
.bio-auth-use-cases .rec-arrow-left{
  z-index: 3;
  left: -10px;
}
.bio-auth-use-cases .rec-arrow-right{
  right: 0;
}

/* ---------- Biometric Authenticatication Page ends here -------------- */

/* ---------- Identity Checker Page begins here -------------- */

.id-checker-hero{
  background: url('https://res.cloudinary.com/dkssurk1s/image/upload/v1648799467/idPass-web-assets/id-checker/hero_ypq3vz.png') center center no-repeat;
  background-size: cover;
  color: #fff;
  padding-top: 8rem;
  padding-bottom: 8rem;
}
.id-checker-hero h1{
  font-size: 4rem;
}
.id-checker-hero p{
  font-size: 1.25rem;
}
.id-checker-channels img{
  width: 100%;
}
.id-checker-widget{
  position: relative;
}
.id-checker-widget::before{
  position: absolute;
  content: "";
  background:#F7EDFC;
  width: 100%;
  height: 75%;
  top: 0;
  z-index: -1;
}
.id-graph-hero{
  background: url('https://res.cloudinary.com/dkssurk1s/image/upload/v1648802865/idPass-web-assets/id-graph/graph-hero_y0cycw.png') center center no-repeat;
  background-size: cover;
}
.id-radar-hero{
  background: url('https://res.cloudinary.com/dkssurk1s/image/upload/v1648803610/idPass-web-assets/id-radar/radar-hero_nju07u.png') center center no-repeat;
  background-size: cover;
}

.ideal-customers .card{
  min-height:500px;
}
.ideal-customers h1{
  font-size:2rem;
}
.ideal-customers p{
  font-size:0.875rem;
  line-height:1.5rem
}

/* ---------- Identity Checker Page ends here -------------- */

/* ---------- Pricing Page starts here -------------- */

.pricing-card .card{
  background: #FAFBFC;
  min-height: 600px;
  border-radius: 10px;
}
.pricing-card .blue .card{
  background: #1633A3 !important;
  color:#fff !important
}
.pricing-card .card .header{
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.pricing-card .card .header span{
  background: rgba(48, 58, 242, 0.15);
  padding: 10px 20px;
  border-radius: 50px;
  font:13px GT-medium;
}
.pricing-card .card .blue .header span{
  background: rgba(255, 255, 255, 0.3);
  padding: 10px 20px;
  border-radius: 50px;
  font:13px GT-medium;
}
.pricing-card .loader{
  color: #303AF2;
  font-size:30px
}
.pricing-card .blue .loader{
  color: #fff;
}
.pricing-card p{
  color: #091E42 !important;
}
.pricing-card small, .pricing-card h6, .pricing-card h5, .pricing-card h3{
  color: #091E42 !important;
  margin: 0 !important;
}
.pricing-card .blue small, .pricing-card .blue h6, .pricing-card .blue h5, .pricing-card .blue h3,  .pricing-card .blue p{
  color: #fff !important;
}
.pricing-card h5, .pricing-card h3{
  padding-top: 20px !important;
}
.pricing-card h6{
  font:15px GT-medium;
  opacity:0.5
}
.pricing-card hr{
  margin-top: 40px;
}
.pricing-card .blue .tick{
  border: 1px solid #fff;
}
.pricing-card .card .tick i{
  background-color: #FAFBFC;
}
.pricing-card .blue .tick i{
  background-color: #1633A3;
  color: #fff;
}
.pricing-card .btn{
  position: absolute;
  bottom: 50px;
  width:90%
}

/* ---------- Pricing Page ends here -------------- */

/* ---------- Use Cases Page starts here -------------- */

.use-cases-hero{
  background: url('https://res.cloudinary.com/dkssurk1s/image/upload/v1648716897/idPass-web-assets/use-cases-hero_cf8ijf.png') center center no-repeat;
  background-size: cover;
  padding:10rem 0rem;
  color:#fff !important;
  text-align: center;
}
.use-case .header{
  background: linear-gradient(53.82deg, #1633A3 2.15%, #A514C9 95.03%);
  padding: 70px 0px;
  text-align: center  ;
}
.use-case .header img{
  height: 40px;
}
.use-case .body{
  margin-top: 20px;
}


.faq .question{
  font-family:GT-bold;
  color: #000 !important;
}
.question-bg{
  background-color: #F0F3FB;
  margin-bottom: 20px;
  cursor: pointer;
}
.faq .answer{
  background-color: #fff;
  padding:20px;
}
.faq .accordion-item{
  border: none !important;
}
.faq .accordion-body{
  padding: 0;
}
.faq .accordion-button{
  background-color: transparent !important;
}
.faq .accordion-collapse .show{
  font-family:GT-bold;
  color:#000 !important
}

/* ---------- Use Cases Page ends here -------------- */

/* ---------- About Us Page starts here -------------- */

.about-us-hero{
  background: linear-gradient(rgba(215, 232, 255, 0.3),rgba(215, 232, 255, 0.3)), url('https://res.cloudinary.com/dkssurk1s/image/upload/v1648716894/idPass-web-assets/about-us-hero_cak3se.png') center center no-repeat;
  background-size: cover;
  padding:10rem 0rem;
  color:#000 !important;
}

.about-us-hero i{
  font-size: 30px;
  width: 50px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  border: 1px solid #091E42;
  color:#091E42;
  margin:50px auto 0px auto;
}

.core-values{
  background: #F4F5F7;
}
.core-values .card{
  border: 1px solid #DEEBFF !important;
  margin-top: 30px;
}
.core-values .card-icon{
  width: 70px;
}
.core-values .card-body h3{
  padding-top: 20px;
}

.core-values .team{
  margin-top: 50px;
}
.core-values .team-members{
  max-height: 750px;
  overflow-y: scroll;
}
.core-values .team-members::-webkit-scrollbar {
  display: none;
}
.core-values .team img{
  width: 100%;
  margin-top: 20px;
}

/* ---------- About Us Page ends here -------------- */

/* ---------- Identity Data Verification Page starts here -------------- */

.id-data-verif-hero{
  padding:10rem 0rem;
  color:#000 !important;
  text-align: center;
}
.id-verif-steps h3{
  display: flex;
  background: #F7F9FE;
  align-items: center;
  justify-content: center;
  height: 100px;
  width:100px;
  color: #1633A3 !important;
  border-radius: 50%;
  border:1px solid #1633A3;
  margin: auto;
  margin-bottom: 20px;
  box-shadow: 0px 0px 0px 40px #F7F9FE;
  
}
.id-verif-steps .line{
  position: relative;
}
.id-verif-steps .line::before{
  position: absolute;
  content: '';
  width: 100%;
  height: 1px;
  left: 0;
  right: 0;
  bottom: -150%;
  background: #1633A3;
  z-index: -1;
}
.why-us-area{
  background: #F4F5F7;
}
.why-us-area .card img{
  height:50px
}
.why-us-area .card p{
  margin-top: 20px !important;
  line-height: 20px;
  color:#172B4D !important;
}
/* ---------- Identity Data Verification Page ends here -------------- */

/* ---------- Career Page starts here -------------- */

.career-hero{
  background: url('https://res.cloudinary.com/dkssurk1s/image/upload/v1648716897/idPass-web-assets/use-cases-hero_cf8ijf.png') center center no-repeat;
  background-size: cover;
  padding:10rem 0rem;
  color:#fff !important;
  text-align: center;
}
.open-positions h5{
  font: 17px GT-bold;
  line-height: 24px;
  padding: 0;
  margin:0;
}
.open-positions h6{
  font: 14px GT-bold;
  font-weight: 400;
  line-height: 8px;
  color:#303AF2;
}
/* ---------- Career Page ends here -------------- */

/* ---------- Blog Page starts here -------------- */

.blog-hero{
  background: #1633A3;
  padding:10rem 0rem;
  color:#fff !important;
  text-align: center;
}
.blog-main{
  background: url('assets/blog/blog-main.png');
  border-radius: 15px;
  margin: 50px 0px;
}
.blog-main .card{
  border-radius: 15px;
  margin-left: 50px;
}
/* ---------- Blog Page ends here -------------- */

/* ---------- Mobility Page starts here -------------- */

.mobility-hero{
  background: #000;
  color:#fff !important;
}
.mobility-verif.id-checker-widget::before{
  position: absolute;
  content: "";
  background:#F4F5F7;
  width: 100%;
  height: 75%;
  top: 0;
  z-index: -1;
}
.core-values.mobility-endpoints{
  background: #F4F5F7;
}
.core-values.mobility-endpoints .card{
  border: none !important;
  border-left: 5px solid #1633A3 !important ;
  background-color: unset !important;
  margin-top: 10px !important;
  border-radius: unset;
}
.mobility-slider{
  background: #000;
  color:#fff !important;
}
.mobility-slider .rec-arrow-left, .mobility-slider .rec-arrow-right{
  display: none;
}
.mobility-slider .rec-slider-container{
  margin: 0;
}
.mobility-slider .rec-pagination{
  position:absolute;
  left:11.5% !important;
  bottom:10%
}
.mobility-slider .rec-dot{
  background-color: #C1C7D0;
}
.mobility-slider .rec-dot_active{
  background-color: #1633A3;
}
/* ---------- Mobility Page ends here -------------- */

/* ----------- Animation starts here ------------- */
@keyframes animation-top {
  0%{
    margin-bottom:-10%;
  }
  100%{
    margin-bottom:0;
  }
}
/* ----------- Animation ends here ------------- */




/* ---------- Responsiveness starts here -------------- */
@media screen  and (max-width:1000px) {
  
  .features-area .first-card {
    min-height: 500px;
  }
  .features-area .first-card .card-body p{
    padding-top:28px;
  }
  .features-area .first-card .card-body .btn{
    width: 100%;
    padding: 15px 0px;
    margin-top: 33px !important;
  }
  /* .consistent-area::before{
    background-size: contain;
    height: 50%;
    top: 40%;
    left: 30%;
  } */
  
  .every-industry-area .rec-arrow-left, .every-industry-area .rec-arrow-right,
  .blog-card-area .rec-arrow-left, .blog-card-area .rec-arrow-right {
    display: none;
  }
  .every-industry-area .rec-slider-container,
  .blog-card-area .rec-slider-container{
    margin: 0;
  }
  
}

@media screen  and (max-width:600px) {
  .navbar-options{
    padding: 20px 10px;
  }
  .navbar-options.products-options{
    min-width: 100%;
  }
  .navbar-options.resources-options{
    min-width: 100%;
  }
  .navbar-options.company-options{
    right:unset;
    left:0;
    min-width: 100%;
  }
  .navbar-options.company-options::before{
    right:unset;
    left:35px
  }
  .hero-area h1{
    font-size: 2.3rem;
  }
  .id-checker-widget::before{
    width: 100%;
    height: 85%;
  }
  
  .id-verif-steps .line::before{
    position: unset;
  }

  .partners-area .card img{
    width:100%
  }

  .features-area .rec-arrow-left, .features-area .rec-arrow-right,
  .consistent-area .rec-arrow-left, .consistent-area .rec-arrow-right, .consistent-area::before{
    display: none;
  }
  .features-area .rec-slider-container, .consistent-area .rec-slider-container{
    margin: 0;
  }

  /* .consistent-area img{
    min-height: 270px;
  } */

  .how-to-use-data-area .tabs p{
    margin:0px 10px 0px 0px;
    justify-content: space-between;
    text-align: center;
  }
  .how-to-use-data-area .tabs p i{
    display: none;
  }
  
  .api-area h1{
    font-size: 27px;
    padding-bottom: 20px;
  }
  .api-area img{
    padding-top: 50px;
    width: 100%;
  }
  .api-area a{
    width: 100%;
  }

  .id-checker-hero h1{
    font-size: 2rem;
  }
}
/* ---------- Responsiveness ends here -------------- */